/* YourChildComponent.css */
.content {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .visible {
    opacity: 1;
    transform: translateY(0);
  }