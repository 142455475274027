html {
  font-size: 14px;
}

body {
  background-color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: normal;
  color: #495057;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 2px;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  line-height: 1.5;
  margin: 0 0 1rem 0;
}

.card {
  margin-bottom: 2rem;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1)
  }

  50% {
    background-color: rgba(165, 165, 165, 0.3)
  }

  100% {
    background-color: rgba(165, 165, 165, 0.1)
  }
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.customer-badge.status-qualified {
  background-color: #C8E6C9;
  color: #256029;
}

.customer-badge.status-unqualified {
  background-color: #FFCDD2;
  color: #C63737;
}

.customer-badge.status-negotiation {
  background-color: #FEEDAF;
  color: #8A5340;
}

.customer-badge.status-new {
  background-color: #B3E5FC;
  color: #23547B;
}

.customer-badge.status-renewal {
  background-color: #ECCFFF;
  color: #694382;
}

.customer-badge.status-proposal {
  background-color: #FFD8B2;
  color: #805B36;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.product-badge.status-instock {
  background: #C8E6C9;
  color: #256029;
}

.product-badge.status-outofstock {
  background: #FFCDD2;
  color: #C63737;
}

.product-badge.status-lowstock {
  background: #FEEDAF;
  color: #8A5340;
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.order-badge.order-delivered {
  background: #C8E6C9;
  color: #256029;
}

.order-badge.order-cancelled {
  background: #FFCDD2;
  color: #C63737;
}

.order-badge.order-pending {
  background: #FEEDAF;
  color: #8A5340;
}

.order-badge.order-returned {
  background: #ECCFFF;
  color: #694382;
}

.image-text {
  vertical-align: middle;
  margin-left: .5rem;
}

.p-multiselect-representative-option {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-representative-option img {
  vertical-align: middle;
  width: 24px;
}

.p-multiselect-representative-option span {
  margin-top: .125rem;
}

.p-column-filter {
  width: 100%;
}

.country-item {
  display: flex;
  align-items: center;
}

.country-item img.flag {
  width: 18px;
  margin-right: .5rem;
}

.flag {
  vertical-align: middle;
}

span.flag {
  width: 44px;
  height: 30px;
  display: inline-block;
}

img.flag {
  width: 30px
}

.mobile {
  display: none;
  height: 35px;
  float: right;
  padding-top: 3px;
}

.desktop {
  height: 50px;
  float: right;

}

.error-message {
  color: red;
  font-size: 0.85em;
  margin: 5px 0 10px 0;
  display: block;
}

@media screen and (max-width: 1399px) {
  .hide {
    display: none !important;
  }
}

.center-and-hide {
  display: flex;
  justify-content: left;
  /* Centers horizontally */
  align-items: center;
  /* Centers vertically */
}
.menu-item-center {
  display: box;
  justify-content: center;

}

/* Media query to hide elements under a specific screen width */
@media screen and (max-width: 1399px) {
  .center-and-hide {
    display: none !important;
  }
}

/* Tablet-specific class - visible on medium-sized screens only */
@media screen and (min-width: 993px) and (max-width: 1399px) {
  .tablet {
    display: flex;
    /* Change this as needed */
    justify-content: center;
    /* Centers horizontally */
    align-items: center;
    /* Centers vertically */

  }
}
@media screen and (max-width: 992px) {
  .tablet {
    display: none !important;
  }
}

/* Hide on larger (LG) screens */
@media screen and (min-width: 1400px) {
  .tablet {
    display: none !important;
  }
}

.p-submenu-list {
  width: 350px !important;
  padding-right: 0px !important
}

.p-menuitem {
  padding-right: 10% !important;

}

span.p-menuitem-text {
  font-weight: bold
}

.white {
  color: white !important;
}

h2.white {
  color: white !important
}

h2.white {
  font-size: 2rem !important;
  font-weight: 400 !important;
  color: white !important
}

.black {
  color: black !important;
}

h2.black {
  font-size: 2rem !important;
  font-weight: 400 !important;
  color: black !important
}

.green {
  color: #03DAC6 !important;
}

h1.green {
  color: #03DAC6 !important
}

h2.green {
  font-size: 2rem !important;
  font-weight: 400 !important;
}

.purple {
  color: #6200EE !important
}

h2.purple {
  color: #6200EE !important
}

h2.purple {
  font-size: 2rem !important;
  font-weight: 400 !important;
}

.p-button-label {
  font-weight: 400 !important;
}

span.p-menuitem-text {
  font-weight: 600 !important;
}

/* YourChildComponent.css */
.content {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.product {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.no-wrap-menubar {
  display: flex;
  flex-wrap: nowrap;
  /* Other styles as needed */
}

.p-menubar-root-list {
  width: 100%;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link {
  padding: 10px 7px;
  max-width: 200px;
}

.p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link {
  padding: 10px 7px;
}

.background1 {



  width: 100%;
  height: 800px;
  /* Cover the entire div */
  background-position: center;
  /* Align the image to the right */
  background-repeat: no-repeat;
  /* Do not repeat the image */

  /* Set a height for the div */
}

.p-menubar .p-menubar-root-list>li {
  flex: 1;
  justify-content: center;
}

.p-listbox .p-listbox-list .p-listbox-item {
  padding: 0.4rem 1rem !important;
}

.background-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #6200EE;
  z-index: 0;
  /* Ensures it stays behind the dynamic div */
}


.dynamic-div {
  position: relative;
  /* Or absolute, depending on your layout needs */
  z-index: 1;
  /* Ensures it is above the background div */
  /* Other styles... */
}


.wtv {
  background-color: #6200EE;
}

.header {
  width: 16%;
  float: left;
  text-align: left;
  padding-top: 20px;
  font-weight: 500;
  font-size: 18px;
  font-family: '__Inter_a64ecd', '__Inter_Fallback_a64ecd';
}

.header_space {
  width: 2%;
  float: left;
  padding-top: 10px;
}

.circular-gradient {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  /* Makes the div circular */

}

.circular-gradient-2 {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.header:hover .dropdown-menu {
  display: block;
}

.hoverable:hover {
  cursor: pointer;
  background-color: white;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

@media (max-width: 993px) {
  img.logo_header {
    height: 35px;
    margin-left: 4px;
    padding-left: 10px;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }


}

@media (max-width: 1650px) {
  .p-menuitem {
    padding-right: 0px !important;

  }
}

@media (min-width: 993px) {
  img.logo_header {
    height: 45px
  }

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }


}